// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import SwiftSpaceRepository from '../../../../api/SwiftSpaceRepository';

export const StoreActions = {
    getReported:'getReported',
    reviewedUser: 'reviewedUser',
    clearState:'clearState'
};

export const actions = {
    [StoreActions.getReported](context) {
        SwiftSpaceRepository.reportdUsers()
        .then((data) => {
            context.commit(StoreMutations.SET_RESULTS,data.results)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    [StoreActions.reviewedUser](context, data) {
        SwiftSpaceRepository.reviewedUser(data)
        .then(() => {
            context.dispatch(StoreActions.getReported);
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_RESULTS,[]);
    }
};
