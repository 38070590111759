// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
    SET_RESULTS:'SET_RESULTS',
    SET_ID: 'SET_ID'
};

export const mutations = {
    [StoreMutations.SET_RESULTS](state,value) {
        state[StoreState.results] = value
    },
    [StoreMutations.SET_ID](state,value) {
        state[StoreState.id] = value
    },
};