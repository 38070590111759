// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import SwiftSpaceRepository from '@/api/SwiftSpaceRepository';

export const StoreActions = {
    getUser:'getUser',
    restrict: 'restrict',
    timeout: 'timeout',
    underage: 'underage',
    superfan: 'superfan',
    clearState:'clearState'
};

export const actions = {
    getUser(context,id) {
        SwiftSpaceRepository.getUser(id)
        .then((data) => {
            context.commit(StoreMutations.SET_USER,data.user)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    restrict(context,id) {
        SwiftSpaceRepository.restrict(id)
            .then(() => {
                Vue.prototype.$notify({'type':'success','content':"User Restricted"})
            })
            .catch((err) => {
                Vue.prototype.$notify({'type':'danger','content':err})
            });
    },
    superfan(context,id) {
        SwiftSpaceRepository.superFan(id)
            .then(() => {
                context.dispatch(StoreActions.getUser, id);
            })
            .catch((err) => {
                Vue.prototype.$notify({'type':'danger','content':err})
            });
    },
    timeout(context,id) {
        SwiftSpaceRepository.timeout(id)
        .then(() => {
            Vue.prototype.$notify({'type':'success','content':"Timeout started"})
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    underage(context,id) {
        SwiftSpaceRepository.underage(id)
        .then(() => {
            Vue.prototype.$notify({'type':'success','content':"Account updated"})
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_USER,null);
    }
};
