// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import SwiftSpaceRepository from '@/api/SwiftSpaceRepository';

export const StoreActions = {
    get:'get',
    clearState:'clearState'
};

export const actions = {
    get(context,id) {
        SwiftSpaceRepository.getEvent(id)
        .then((data) => {
            context.commit(StoreMutations.SET_EVENT,data.event)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_EVENT,null);
    }
};
