// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
    SET_EVENT:'SET_EVENT'
};

export const mutations = {
    [StoreMutations.SET_EVENT](state,value) {
        state[StoreState.event] = value
    }
};