// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
    SET_MESSAGES:'SET_MESSAGES',
    SET_USERS:'SET_USERS',
    SET_PAGE: 'SET_PAGE',
    SET_LAT: 'SET_LAT',
    SET_LNG: 'SET_LNG',
    SET_DISTANCE: 'SET_DISTANCE',
};

export const mutations = {
    [StoreMutations.SET_USERS](state,value) {
        state[StoreState.users] = value
    },
    [StoreMutations.SET_PAGE](state,value) {
        state[StoreState.page] = value
    },
    [StoreMutations.SET_LAT](state,value) {
        state[StoreState.lat] = value
    },
    [StoreMutations.SET_LNG](state,value) {
        state[StoreState.lng] = value
    },
    [StoreMutations.SET_DISTANCE](state,value) {
        state[StoreState.distance] = value
    },
};