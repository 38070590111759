// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import SwiftSpaceRepository from '@/api/SwiftSpaceRepository';

export const StoreActions = {
    get:'get',
    clearState:'clearState'
};

export const actions = {
    get(context,id) {
        SwiftSpaceRepository.getMe(id)
        .then((data) => {
            console.log(data.me)
            context.commit(StoreMutations.SET_ME,data.me)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_ME,null);
    }
};
