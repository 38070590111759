// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
    SET_BUSINESS:'SET_BUSINESS'
};

export const mutations = {
    [StoreMutations.SET_BUSINESS](state,value) {
        state[StoreState.business] = value
    }
};