<template>
  <div class="bg-gray">
    <div class="container-fluid">
      <div class="user-card">
        <h2>Businesses</h2>

        <div class="row">
          <div class="col-sm-12" style="margin-top: 5px; margin-bottom: 5px;">
            <p>Page: {{page}}</p>
          </div>

          <div class="col-sm-12">
            <button @click="prevPage" class="btn btn-default" style="margin-right: 10px;">Prev</button>

            <button @click="nextPage" class="btn btn-default">Next</button>
          </div>
        </div>

        <div class="row"
            v-for="(result, index) in results" :key="index"
        >
          <div class="col-sm-2" style="margin-left: 10px;">
            <business-photo :business="result" />
            <router-link :to="`/businesses/${result.id}`">{{result.name}}</router-link>
          </div>
          <div class="col-sm-4">
            <p>{{result.type}}</p>
            <p>{{result.address}} {{result.city}} {{result.state}} {{result.zip}} {{result.country}}</p>
            <a :href="result.website">{{result.website}}</a>

          </div>
          <div class="col-sm-2">
            <p>Approved: {{result.approved ? "Yes" : "No"}}</p>
            <p>Boosted: {{result.boosted ? "Yes" : "No"}}</p>

          </div>
          <div class="col-sm-4"></div>

          <div class="col-sm-12">

            <hr>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import { StoreMutations } from "./store/mutations";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'
import BusinessPhoto from "@/components/swiftspace/BusinessPhoto.vue";

export default {
  name: "ReportedUsers",
  components: {
    BusinessPhoto
  },
  data() {
    return {
      suspendConfirm: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.dispatch(StoreActions.search);
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    nextPage() {
      this.setPage( this.page + 1)
    },
    prevPage() {
      if(this.page === 0) {
        return
      }

      this.setPage( this.page - 1)
    },
    setPage(page) {
      this.commit(StoreMutations.SET_PAGE, page);
      this.load();
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
        state.results,
        state.page
    ])
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  width:100px;
  height:100px;
  border-radius: 50px;
  object-fit: cover;
}

@media (max-width:767px) {
  .user-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>