<template>
  <div>
        <div>
      <h2>Search</h2>
      <label style="width: 30%; text-align:center;">
        <div class="input-group">
          <gmap-autocomplete
        class="form-control"
          @place_changed="setPlace">
        </gmap-autocomplete>
        <span class="btn btn-primary input-group-addon" @click="addMarker">Go</span>
        </div>
      </label>
      <br/>

    </div>
    <br />
    <gmap-map ref="gmap" :center="center" :zoom="zoom" style="width: 100%; height: 600px" @bounds_changed="handleBoundsChanged">
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
      </gmap-info-window>

      <gmap-cluster>
        <gmap-marker
          v-for="(m, index) in markers"
          :key="m.id"
          :position="m.position"
          @click="toggleInfoWindow(m,index)"
        ></gmap-marker>
      </gmap-cluster>
    </gmap-map>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  data() {
    return {
      zoom:10,
      center: {
        lat: 39.80047319091619,
        lng:-76.9830358
      },
      markers: [],
      places: [],
      infoWindowPos: null,
      infoWinOpen: false,
      currentPlace: null,
      currentMidx: null,
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      timeout: null
    };
  },

  mounted() {
    // this.geolocate();
  },
  methods: {
    handleBoundsChanged() {

      window.clearTimeout(this.timeout);
      var self = this
      this.timeout = window.setTimeout(function () {
        const bounds = self.$refs.gmap.$mapObject.getBounds();
        const center = bounds.getCenter();

        const northEast = bounds.getNorthEast();
        const radiusInMeters = self.computeDistance(center, northEast);
        const radiusInMiles = radiusInMeters / 1609.34;

        const data = { lat: center.lat(), lng: center.lng(), distance: radiusInMiles }
        console.log(data)
        self.$emit('map-updated', data);
      }, 500);
    },
    computeDistance(p1, p2) {
      const R = 6378137; // Radius of the Earth in meters
      const dLat = this.rad(p2.lat() - p1.lat());
      const dLng = this.rad(p2.lng() - p1.lng());
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(this.rad(p1.lat())) * Math.cos(this.rad(p2.lat())) *
          Math.sin(dLng / 2) * Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c; // Distance in meters
    },
    rad(x) {
      return x * Math.PI / 180;
    },
    calculateDistance(center, zoom) {
      // Calculate a small offset based on the zoom level
      const offset = 1 / Math.pow(2, zoom);
      const latOffset = center.lat() + offset;
      const lngOffset = center.lng() + offset;
      return this.haversineDistance(center.lat(), center.lng(), latOffset, lngOffset);
    },
    haversineDistance(lat1, lng1, lat2, lng2) {
      const toRadians = (degree) => degree * (Math.PI / 180);
      const R = 3958.8; // Earth radius in miles
      const dLat = toRadians(lat2 - lat1);
      const dLng = toRadians(lng2 - lng1);
      const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
          Math.sin(dLng / 2) * Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c;
    },
    toggleInfoWindow(marker, idx) {
      this.center = marker.position
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    setPlace(place) {
      console.log(place)
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        // this.markers.push({ position: marker });
        // this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
        this.zoom = 14
      }
    },
    clearMarkers() {
      this.markers = [];
    },
    addMarkerWithCoords(lat, lng, infoText) {
      const marker = {
        lat: lat,
        lng: lng,
      };

      this.markers.push({ id: this.generateUUID(), position: marker, infoText: infoText });
      
    },
    generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
},
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
  },
};
</script>
