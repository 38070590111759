// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import SwiftSpaceRepository from "@/api/SwiftSpaceRepository";

export const StoreActions = {
    getUsers:'getUsers',
    clearState:'clearState'
};

export const actions = {
    getUsers(context) {
        SwiftSpaceRepository.usersWithLocation(context.state.lat, context.state.lng, context.state.distance, context.state.page)
        .then((data) => {
            context.commit(StoreMutations.SET_USERS, data.users)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err.message})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_USERS,[]);
    }
};
