import { state } from './state'
import { mutations } from './mutations'
import { actions } from './actions'
import { getters } from './getters'

export default {
  namespaced:true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
  path:'swiftspace/reported/businesses'//used for namespacing
}
