// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import SwiftSpaceRepository from '../../../../api/SwiftSpaceRepository';

export const StoreActions = {
    search: 'search',
    setFeatured: 'setFeatured',
    clearState:'clearState'
};

export const actions = {
    [StoreActions.search](context) {
        SwiftSpaceRepository.searchThemes()
        .then((data) => {
            context.commit(StoreMutations.SET_RESULTS, data.themes)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    [StoreActions.setFeatured](context, data) {
        SwiftSpaceRepository.updateThemeFeatured(data.id, {featured: data.featured})
            .then(() => {
                context.dispatch(StoreActions.search);
            })
            .catch((err) => {
                Vue.prototype.$notify({'type':'danger','content':err})
            });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_RESULTS,[]);
        context.commit(StoreMutations.SET_PAGE,0);
    }
};
