// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import SwiftSpaceRepository from '../../../../api/SwiftSpaceRepository';

export const StoreActions = {
    create: 'create',
    clearState:'clearState'
};

export const actions = {
    [StoreActions.create](context, form) {
        SwiftSpaceRepository.createPost(form)
            .then(() => {
            })
            .catch((err) => {
                Vue.prototype.$notify({'type':'danger','content':err})
            });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_RESULTS,[]);
        context.commit(StoreMutations.SET_PAGE,0);
    }
};
