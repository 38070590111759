// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
    SET_USER:'SET_USER'
};

export const mutations = {
    [StoreMutations.SET_USER](state,value) {
        state[StoreState.user] = value
    }
};