<template>
  <div class="bg-gray">
    <div class="container-fluid">
      <div class="user-card">
        <h2>Pending Events</h2>

        <div class="row"
            v-for="(result, index) in results" :key="index"
        >
          <div class="col-sm-12">
            <div v-if="result.photo">
              <img
                  :src="result.photo.url"
                  style="height: 200px"
              />
            </div>
            <div v-else-if="result.business.photo">
              <img
                  :src="result.business.photoUrl"
                  style="height: 200px"
              />
            </div>
            <router-link :to="`/events/${result.id}`">{{result.title}}</router-link>
            <br><br>

            <button @click="review(result, true)" class="btn btn-default">Approve</button>
            <br><br>
            <button @click="showingRejectId = result.id" class="btn btn-danger">Reject</button>

            <div v-if="showingRejectId === result.id" style="margin-top: 10px">
              <div class="row">
                <div class="col-sm-4 col-sm-offset-4">
                  <input type="text" placeholder="Reason" class="form-control" v-model="rejectionReason" />
                  <br><br>
                  <button @click="review(result, false)" class="btn btn-danger">Deny</button>
                </div>
              </div>
            </div>


            <hr>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'

export default {
  name: "PendingEvents",
  components: {
  },
  data() {
    return {
      suspendConfirm: false,
      rejectionReason: null,
      showingRejectId: null
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.dispatch(StoreActions.getReported);
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    review(report, approved) {
      this.dispatch(StoreActions.reviewedReported, {
        id: report.id,
        approved: approved,
        rejectionReason: this.rejectionReason
      });
      this.showingRejectId = null
      this.rejectionReason = ""
    },
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.results
    ])
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  width:100px;
  height:100px;
  border-radius: 50px;
  object-fit: cover;
}

@media (max-width:767px) {
  .user-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>