// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import { StoreState } from './state';
import SwiftSpaceRepository from '../../../../api/SwiftSpaceRepository';

export const StoreActions = {
    search: 'search',
    clearState:'clearState'
};

export const actions = {
    [StoreActions.search](context) {
        SwiftSpaceRepository.searchAwardAnswers(context.state[StoreState.page])
        .then((data) => {
            context.commit(StoreMutations.SET_RESULTS, data.answers)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_RESULTS,[]);
        context.commit(StoreMutations.SET_PAGE,0);
    }
};
