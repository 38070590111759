import { render, staticRenderFns } from "./BusinessSearch.vue?vue&type=template&id=4513d6db&scoped=true"
import script from "./BusinessSearch.vue?vue&type=script&lang=js"
export * from "./BusinessSearch.vue?vue&type=script&lang=js"
import style0 from "./BusinessSearch.vue?vue&type=style&index=0&id=4513d6db&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4513d6db",
  null
  
)

export default component.exports