<template>
  <div>
  <div class="bg-gray">
    <div class="container-fluid">
      <div class="user-card">
        <h2>Awards</h2>

        <button @click="showingCreate = true" class="btn btn-primary">Create</button>

        <table class="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Date</th>
              <th>Type</th>
              <th>Options</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          <tr
               v-for="(result, index) in results" :key="index"
          >
            <td>{{result.name}}</td>
            <td>{{formatDate(result.startDate)}}</td>
            <td>{{result.type}}</td>
            <td><span @click="notifyWithAward(result)">{{optionsPreview(result)}}</span></td>
            <td>
              <button @click="edit(result)" class="btn btn-primary">Edit</button>
              <button @click="clone(result)" class="btn btn-default" style="margin-left: 10px;">Clone</button>
              <button class="btn btn-default" style="margin-left: 10px;">Results</button>
            </td>
          </tr>
          </tbody>
        </table>


      </div>
    </div>
  </div>
  <create-award-modal :showing="showingCreate" @close="showingCreate = false" @create="submitCreate" :existing="editAward" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'
import moment from 'moment'
import CreateAwardModal from "@/components/swiftspace/awardsSearch/CreateAwardModal.vue";

export default {
  name: "AwardSearch",
  components: {
    CreateAwardModal
  },
  data() {
    return {
      suspendConfirm: false,
      showingCreate: false,
      editAward: null
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    edit(award) {
      console.log(award)
      let options = award.options.map((it) => { return it.option })
      let startDate = moment(award.startDate)

      this.editAward = {
        options: options,
        id: award.id,
        name: award.name,
        type: award.type,
        date: startDate.format("YYYY-MM-DD")
      }
      this.showingCreate = true
    },
    clone(award) {
      let options = award.options.map((it) => { return it.option })

      this.editAward = {
        options: options,
        id: null,
        name: award.name,
        type: award.type,
        date: ""
      }
      this.showingCreate = true
    },
    load() {
      this.dispatch(StoreActions.search);
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    formatDate(date) {
      return moment(date).format("MMM DD yyyy")
    },
    getOptions(award) {
      return award.options.map(it => {return it.option})
    },
    optionsPreview(award) {
      let options = this.getOptions(award)
      let text = options.join()
      if(text.length > 30) {
        return text.substring(0, 30) + "..."
      }

      return text
    },
    notifyWithAward(award) {
      let options = this.getOptions(award)
      this.$notify({'content' : options.join("\n")})
    },
    submitCreate(form) {
      if(form.id) {
        console.log("update")
        this.dispatch(StoreActions.update, form);
      } else {
        console.log("create")
        this.dispatch(StoreActions.create, form);
      }
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
        state.results,
        state.page
    ])
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  width:100px;
  height:100px;
  border-radius: 50px;
  object-fit: cover;
}

@media (max-width:767px) {
  .user-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>