export const StoreState = {
    dailyActiveAverage:'dailyActiveAverage',
    dailySignups:'dailySignups',
    dailyMessages:'dailyMessages',
    dailyOpens:'dailyOpens',
    swiftSpaceStats: 'swiftSpaceStats',
    reportedStats: 'reportedStats',
    flaggedStats: 'flaggedStats',
}

export const state = {
    [StoreState.swiftSpaceStats]:null,
    [StoreState.reportedStats]:null,
    [StoreState.flaggedStats]:null,
    [StoreState.dailyActiveAverage]:null,
    [StoreState.dailySignups]:null,
    [StoreState.dailyMessages]:null,
    [StoreState.dailyOpens]:null,
}
