// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
    SET_RESULTS:'SET_RESULTS',
    SET_PAGE: 'SET_PAGE'
};

export const mutations = {
    [StoreMutations.SET_RESULTS](state,value) {
        state[StoreState.results] = value
    },
    [StoreMutations.SET_PAGE](state,value) {
        state[StoreState.page] = value
    },
};