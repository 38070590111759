<template>
  <div class="row" style="padding-top: 20px">
    <div
      class="col-sm-10 col-sm-offset-1"
      style="background: white; border-radius: 10px"
    >

    <google-map ref="map" @map-updated="coordsChanged"/>

      <div class="row" style="margin-top: 10px;">
        <div class="col-sm-4 col-sm-offset-4">
          <button @click="prevPage" class="btn btn-primary" style="margin-right:20px">Prev</button>
          <button @click="nextPage" class="btn btn-primary">Next</button>
        </div>
      </div>

      <table class="table table-striped">
        <thead>
          <tr>
            <th>Username</th>
            <th>Name</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="user in users" :key="user.id" style="text-align: left;">
            <router-link :to="`/users/${user.id}`">
            <img v-if="user.photoUrl" :src="user.photoUrl" style="height: 100px; width: 100px; border-radius: 50px; object-fit: cover;margin-top: 10px; margin-bottom: 10px;" />
            <p v-else></p>
            </router-link>
            <td>@{{ user.username }}</td>
            <td>{{ user.firstName }} {{ user.lastName }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import { StoreState as state } from "./store/state";
import GoogleMap from './GoogleMap.vue'
import {StoreMutations} from "@/components/swiftspace/usersByLocation/store/mutations";
export default {
  name: "UsersByLocation",
  components: {GoogleMap},
  data() {
    return {
      center: {
        lat: 39.80047319091619,
        lng:-76.9830358
      },
      markers: {
        position: {
          lat: 39.80047319091619,
          lng:-76.9830358
        }
      }
    };
  },
  mounted() {
  },
  destroyed() {
    this.dispatch(StoreActions.clearState);
  },
  methods: {
    coordsChanged(val) {
      this.commit(StoreMutations.SET_DISTANCE, val.distance);
      this.commit(StoreMutations.SET_LAT, val.lat);
      this.commit(StoreMutations.SET_LNG, val.lng);
      this.commit(StoreMutations.SET_PAGE, 0);
      this.getUsers();
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    getUsers(val) {
      this.dispatch(StoreActions.getUsers, val);
    },
    nextPage() {
      this.commit(StoreMutations.SET_PAGE,this.page + 1)
      this.getUsers()
    },
    prevPage() {
      if(this.page > 0) {
        this.commit(StoreMutations.SET_PAGE,this.page - 1)
      }
      this.getUsers()
    },
  },
  computed: {
    ...mapState(StoreIndex.path, [state.users, state.page]),
  },
  watch: {
    users(newVal) {
      console.log(newVal.length)
      this.$refs.map.clearMarkers();
      
      var self = this;
      setTimeout(() => {
        console.log(newVal.length)
        newVal.forEach(user => {
          let infoText = `<a target="_blank" href="/#/users/${user.id}">${user.firstName} ${user.lastName}</a>`;
          self.$refs.map.addMarkerWithCoords(user.lat, user.lng, infoText);
        });
      }, 1000);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  color: gray;
}

.itemValue {
  color: black;
  font-size: medium;
}
</style>
