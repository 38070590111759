// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import SwiftSpaceRepository from '../../../../api/SwiftSpaceRepository';

export const StoreActions = {
    search: 'search',
    update: 'update',
    delete: 'delete',
    clearState:'clearState'
};

export const actions = {
    [StoreActions.search](context) {
        SwiftSpaceRepository.searchAcronyms()
        .then((data) => {
            context.commit(StoreMutations.SET_RESULTS, data.acronyms)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    [StoreActions.update](context, data) {
        SwiftSpaceRepository.updateAcronym(data.id, data)
            .then(() => {
                context.dispatch(StoreActions.search);
            })
            .catch((err) => {
                Vue.prototype.$notify({'type':'danger','content':err})
            });
    },
    [StoreActions.delete](context, id) {
        SwiftSpaceRepository.deleteAcronym(id)
            .then(() => {
                context.dispatch(StoreActions.search);
            })
            .catch((err) => {
                Vue.prototype.$notify({'type':'danger','content':err})
            });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_RESULTS,[]);
        context.commit(StoreMutations.SET_PAGE,0);
    }
};
