<template>
  <div class="row" style="padding-top: 20px">
    <div class="col-sm-10 col-sm-offset-1" style="background:white;border-radius:10px" v-if="item">
      <img :src="item.photoUrl" height="300px" style="border-radius:10px;margin-top:10px"/>
      <div class="row">
        <div class="col-xs-12"
        style="margin-bottom:20px;margin-top: 20px;">
          <router-link v-if="item.user" :to="`/users/${item.user.id}`">{{item.user.firstName}} {{item.user.lastName}} ({{item.user.username}})</router-link>

        </div>
      </div>

    <br>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'
export default {
  name: "Me",
  components: {
  },
  data() {
    return {

    };
  },
  mounted() {
      this.get()
  },
  destroyed() {
    this.dispatch(StoreActions.clearState);
  },
  methods: {
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    get() {
      let id = this.$route.params.id
      this.dispatch(StoreActions.get,id);
    },
    parseValue(val) {
      if(!val) {
        return ""
      }

      return val
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.item
    ]),
    items() {
      if(!this.item) {
        return []
      }
      return [

      ]
    }
  },
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

label {
  color:gray;
}

.itemValue {
  color:black;
  font-size: medium;
}
</style>
