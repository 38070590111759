// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import SwiftSpaceRepository from '../../../api/SwiftSpaceRepository';

export const StoreActions = {
    swiftSpaceStats: 'swiftSpaceStats'
};

export const actions = {
    swiftSpaceStats(context) {
        SwiftSpaceRepository.stats()
        .then((data) => {
            let stats = [
                {
                    title:"Users",
                    value:data.numUsers
                },
                {
                    title: 'Attending',
                    value: data.numTourAttending
                },
                {
                    title: 'Listening',
                    value: data.listeningCount
                },
                {
                    title: 'Me Count',
                    value: data.meCount
                },
                {
                    title: 'SuperFan Count',
                    value: data.superFanCount
                },
                {
                    title: 'Recent Signups',
                    value: data.recentSignups
                },
                {
                    title: 'Recent Active',
                    value: data.recentActive
                },
                {
                    title: 'Recent Opens',
                    value: data.recentOpens
                }
            ]
            context.commit(StoreMutations.SET_SWIFTSPACE_STATS,stats);

            let reportedStats = [
                {
                    title: 'Reported Users',
                    value: data.reportedUsers
                },
                {
                    title: 'Reported Events',
                    value: data.reportedEvents
                },
                {
                    title: 'Reported Businesses',
                    value: data.reportedBusinesses
                },
                {
                    title: 'Reported Me\'s',
                    value: data.reportedMes
                },
            ]

            let flaggedStats = [
                {
                    title: 'Flagged Me\'s',
                    value: data.flaggedMes
                }
            ]


            context.commit(StoreMutations.SET_REPORTED_STATS, reportedStats);
            context.commit(StoreMutations.SET_FLAGGED_STATS, flaggedStats);
        })
        .catch((err) => {
            console.log(err)
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
};
