<template>
  <div>
    <!-- Single gradient box that adjusts based on the number of colors -->
    <div class="gradient-box" :style="{ background: generateGradient() }">
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorGradient",
  props: ["colors"],
  methods: {
    generateGradient() {
      if (this.colors.length === 1) {
        // If there is only one color, apply it as a solid background
        return `${this.colorStr(this.colors[0])}`;
      } else {
        // For multiple colors, create a linear gradient
        let gradient = 'linear-gradient(to right';
        this.colors.forEach((color, index) => {
          let position = (100 / (this.colors.length - 1)) * index;
          gradient += `, ${this.colorStr(color)} ${position}%`;
        });
        gradient += ')';
        return gradient;
      }
    },
    colorStr(color) {
      if(color.includes("#")) {
        return color
      }

      return `#${color}`
    }
  }
}
</script>

<style scoped>
.gradient-box {
  height: 20px;
  width: 100px;
  margin: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
}
</style>
