<template>
  <div>
    <modal :value="showing" @hide="$emit('close')" @input="$emit('close')" title="Create Post" :footer="false">
      <div class="modal-body">

        <div style="margin-top: 10px">
          <label>Post</label>
          <input class="form-control" v-model="post" placeholder="..." style="margin-top: 10px"/>
        </div>

        <div style="margin-top: 10px">
          <label>User</label>
          <select class="form-control" v-model="user" style="margin-top: 10px">
            <option value="92">Swift Space</option>
            <option value="2">Swift Stock</option>
          </select>
        </div>

        <div style="margin-top: 10px">
          <label>SuperFan Link</label>
          <select class="form-control" v-model="superFanLink">
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>

        <div style="margin-top: 10px">
          <label>Share To Feed</label>
          <select class="form-control" v-model="shareToFeed" style="margin-top: 10px">
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>

        <div style="margin-top: 10px">
          <label>Hide From SuperFan</label>
        <select class="form-control" v-model="hideFromSuperFan" style="margin-top: 10px">
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
        </div>

        <div style="margin-top: 10px">
          <label>Ranking</label>
         <input class="form-control" v-model="ranking" placeholder="..." style="margin-top: 10px"/>
        </div>

        <hr>
        <p>Notification</p>
        <div style="margin-top: 10px">
          <label>Title</label>
          <input class="form-control" v-model="notificationTitle" placeholder="..." style="margin-top: 10px"/>
        </div>
        <div style="margin-top: 10px">
          <label>Message</label>
          <input class="form-control" v-model="notificationMessage" placeholder="..." style="margin-top: 10px"/>
        </div>

        <div style="margin-top: 10px">
          <label>Notification Type</label>
          <select class="form-control" v-model="notificationType" style="margin-top: 10px">
            <option value="App">App</option>
            <option value="Merch">Merch</option>
            <option value="SuperFan">SuperFan</option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" @click="create()">Create</button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      post: '',
      user: 92,
      superFanLink: 0,
      shareToFeed: 1,
      hideFromSuperFan: 0,
      ranking: 0,
      notificationTitle: "",
      notificationMessage: "",
      notificationType: "App"
    }
  },
  props: {
    showing: {
      default: false
    }
  },
  methods: {
    create() {
      var notification = null

      if(this.notificationTitle.length > 0 && this.notificationMessage.length > 0) {
        notification = {
          title: this.notificationTitle,
          message: this.notificationMessage,
          type: this.notificationType
        }
      }

      this.$emit('create', {
        post: this.post,
        userId: this.user,
        superFanLink: this.superFanLink,
        shareToFeed: this.shareToFeed,
        hideFromSuperFan: this.hideFromSuperFan,
        ranking: this.ranking,
        notification: notification
      });
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.modal-body {
  padding: 15px;
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px;
  border-top: 1px solid #e9ecef;
}

.option-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.option-text {
  margin: 0;
  text-align: left;
  flex-grow: 1;
}

.option-item button {
  margin-left: 10px;
}
</style>
