<template>
  <div class="row" style="padding-top: 20px">
    <div class="col-sm-10 col-sm-offset-1">
      <div class="row" style="margin-top: 20px; border-radius: 10px">
        <div class="col-xs-12 col-sm-12">
          <div class="pane">
            <img src="@/assets/swiftspace.png" class="icon swiftspace-icon" />
            <br /><br />
            <card
              v-for="stat in swiftSpaceStats"
              :key="`swiftspace${stat.title}`"
              :title="stat.title"
              :titleColor="'#5c8bc4'"
              :value="stat.value"
            />
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 20px; border-radius: 10px">
        <div class="col-xs-12 col-sm-12">
          <div class="pane">
            <br /><br />
            <card
                v-for="stat in reportedStats"
                :key="`swiftspace${stat.title}`"
                :title="stat.title"
                :titleColor="'#5c8bc4'"
                :value="stat.value"
            />
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 20px; border-radius: 10px">
        <div class="col-xs-12 col-sm-12">
          <div class="pane">
            <br /><br />
            <card
                v-for="stat in flaggedStats"
                :key="`swiftspace${stat.title}`"
                :title="stat.title"
                :titleColor="'#5c8bc4'"
                :value="stat.value"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StoreActions } from "./store/actions";
import { mapState } from "vuex";
import { StoreState as state } from "./store/state";
import StoreIndex from "./store/_StoreIndex";
import Card from "./Card.vue";
export default {
  name: "Home",
  components: {
    Card
  },
  data() {
    return {
      loginStore: StoreIndex.path,
    };
  },
  mounted() {
    if (!this.$hasSession()) {
      console.log("has session");
      // this.$router.push('/login')
    }
    this.getStats();
  },
  methods: {
    commit(path, value) {
      this.$store.commit(`${this.loginStore}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${this.loginStore}/${path}`, value);
    },
    getStats() {
      this.dispatch(StoreActions.swiftSpaceStats);
    },
  },
  computed: {
    ...mapState(StoreIndex.path, [
      state.swiftSpaceStats,
      state.flaggedStats,
      state.reportedStats
    ]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon {
  border-radius: 10px;
  height: 50px;
  width: 50px;
}

.roomie-icon {
  margin-top:20px;
  padding:10px;
  background:white;
  height:60px;
  width:60px;
}


.swiftspace-icon {
  margin-top:20px;
}
</style>
