<template>
  <div class="row" style="padding-top: 20px">
    <div class="col-sm-10 col-sm-offset-1" style="background:white;border-radius:10px" v-if="event">
      <img v-if="evnt.photo" :src="evnt.photo.url" height="300px" style="border-radius:10px;margin-top:10px"/>
      <img v-else-if="evnt.business.photoUrl" :src="evnt.business.photoUrl" height="300px" style="border-radius:10px;margin-top:10px"/>
      <h3>{{evnt.title}}</h3>
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-4"
        style="margin-bottom:20px"
        v-for="item in items"
        :key="item.label">
          <label>{{item.label}}</label>
          <p class="itemValue">{{item.value}}</p>
        </div>
      </div>

    <br>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'
export default {
  name: "Event",
  components: {
  },
  data() {
    return {
      showReportedPosts: false,
      showReportedComments: false,
      showReported: false,
      showPosts: false,
      showComments: false,
      showDeletedPosts: false,
      filter: "",
      deletedFilter: ""
    };
  },
  mounted() {
      this.get()
  },
  destroyed() {
    this.dispatch(StoreActions.clearState);
  },
  methods: {
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    get() {
      let id = this.$route.params.id
      this.dispatch(StoreActions.get,id);
    },
    parseValue(val) {
      if(!val) {
        return ""
      }

      return val
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.event
    ]),
    evnt() {
      return this.event
    },
    items() {
      if(!this.evnt) {
        return []
      }
      return [
        {
          label:'Name',
          value: this.parseValue(this.evnt.title)
        },
        {
          label:'Start Date',
          value:this.parseValue(this.evnt.startDate)
        },
        {
          label:'End Date',
          value:this.parseValue(this.evnt.endDate)
        },
        {
          label:'Virtual',
          value:this.parseValue(this.evnt.virtual)
        },
        {
          label:'Venue',
          value:this.parseValue(this.evnt.venue)
        },
        {
          label:'Street',
          value:this.parseValue(this.evnt.street)
        },
        {
          label:'City',
          value:this.parseValue(this.evnt.city)
        },
        {
          label:'State',
          value:this.parseValue(this.evnt.state)
        },
        {
          label:'Zip',
          value:this.parseValue(this.evnt.zip)
        },
        {
          label:'Country',
          value:this.parseValue(this.evnt.country)
        },
        {
          label:'Lat',
          value:this.parseValue(this.evnt.lat)
        },
        {
          label:'Lng',
          value:this.parseValue(this.evnt.lng)
        },
        {
          label:'Timezone',
          value:this.parseValue(this.evnt.timeZone)
        },
        {
          label:'Link',
          value:this.parseValue(this.evnt.link)
        },
        {
          label:'Desc',
          value:this.parseValue(this.evnt.desc)
        },
        {
          label:'Needs Review',
          value:this.parseValue(this.evnt.needsReview)
        },
        {
          label:'Rejection Reason',
          value:this.parseValue(this.evnt.rejectionReason)
        },
        {
          label:'Approved',
          value: `${this.evnt.approved ? 'Yes' : 'No'}`
        },
        {
          label:'Boosted',
          value: `${this.evnt.boosted ? 'Yes' : 'No'}`
        },
        {
          label:'List Views',
          value: `${this.evnt.listViews}`
        },{
          label:'Detail Views',
          value: `${this.evnt.detailViews}`
        },{
          label:'Link Views',
          value: `${this.evnt.linkViews}`
        }
      ]
    }
  },
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

label {
  color:gray;
}

.itemValue {
  color:black;
  font-size: medium;
}
</style>
