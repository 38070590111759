export const StoreState = {
    messages:'messages',
    users:'users',
    page: 'page',
    lat: 'lat',
    lng: 'lng',
    distance: 'distance',
}

export const state = {
    [StoreState.users]: [],
    [StoreState.page]: 0,
    [StoreState.lat]: 39.80047319091619,
    [StoreState.lng]: -76.9830358,
    [StoreState.distance]: 10,
}
