<template>
  <div class="bg-gray">
    <div class="container-fluid">
      <div class="user-card">
        <h2>Acronyms</h2>

        <table class="table table-striped">
          <thead>
            <tr>
              <th>Acronym</th>
              <th>Meaning</th>
              <th>Approved</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          <tr
               v-for="(result, index) in results" :key="index"
          >
            <td>{{result.acronym}}</td>
            <td>{{result.meaning}}</td>

            <td>{{result.approved ? 'Yes' : 'No'}}</td>
            <td>
              <template>
                <button  @click="update(result.id, result.acronym, result.meaning, true)" class="btn btn-default" style="margin-right: 10px;">Approve</button>
                <button  @click="update(result.id, result.acronym, result.meaning,false)" class="btn btn-warning" style="margin-right: 10px;">Reject</button>
                <button  @click="showConfirmDelete(result.id)" class="btn btn-danger">Delete</button>
              </template>
            </td>
          </tr>
          </tbody>
        </table>


      </div>
    </div>
    <confirmation-modal :showing="showingConfirm" @canceled="cancelDelete" @hidden="cancelDelete" @confirmed="deleteAcronym" :user-message="'Are you sure you want to delete this acronym?'" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'
import ConfirmationModal from "@/components/ConfirmationModal.vue";

export default {
  name: "ReportedUsers",
  components: {
    ConfirmationModal
  },
  data() {
    return {
      suspendConfirm: false,
      showingConfirm: false,
      deleteAcronymId: null
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.dispatch(StoreActions.search);
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    update(id, acronym, meaning, approve) {
      this.dispatch(StoreActions.update, {
        id: id,
        acronym: acronym,
        meaning: meaning,
        approved: approve
      })
    },
    deleteAcronym() {
      if(!this.deleteAcronymId) {
        return
      }

      this.dispatch(StoreActions.delete, this.deleteAcronymId)
      this.cancelDelete()
    },
    cancelDelete() {
      this.showingConfirm = false
      this.deleteAcronymId = null
    },
    showConfirmDelete(id) {
      this.deleteAcronymId = id
      this.showingConfirm = true
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
        state.results,
        state.page
    ])
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  width:100px;
  height:100px;
  border-radius: 50px;
  object-fit: cover;
}

@media (max-width:767px) {
  .user-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>