<script >
export default {
  props: [
    "business"
  ]
}
</script>

<template>
<div>
  <div v-if="business.photo">
    <img
        :src="business.photoUrl"
        style="height: 150px;"
    />
  </div>
</div>
</template>

<style scoped>

</style>