<template>
  <div class="bg-gray">
    <div class="container-fluid">
      <div class="user-card">
        <h2>Reported Users</h2>

        <div class="row"
            v-for="(result, index) in results" :key="index"
        >
          <div class="col-sm-12" v-if="result.reported">
            <div v-if="result.reported.photo">
              <img 
                :src="result.reported.photoUrl"
                height="300" 
              />
            </div>
            <router-link v-if="result.reported" :to="`/users/${result.reportedId}`">{{result.reported.firstName}} {{result.reported.lastName}} ({{result.reported.username}})</router-link>

            <p><strong>{{result.reason}}</strong></p>

            <router-link v-if="result.reportedBy" :to="`/users/${result.reportedById}`">Reported by: {{result.reportedBy.firstName}} {{result.reportedBy.lastName}} ({{result.reportedBy.username}})</router-link>
            <br><br>

            <button @click="review(result, true, false)" class="btn btn-warning">Timeout User</button>
            <br><br>
            <button @click="review(result, false, true)" class="btn btn-primary">Under Age</button>
            <br><br>
            <button @click="review(result, false, false)" class="btn btn-default">Mark Reviewed</button>
            <hr>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'

export default {
  name: "ReportedUsers",
  components: {
  },
  data() {
    return {
      suspendConfirm: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.dispatch(StoreActions.getReported);
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    review(report, timeout, underage) {
      this.dispatch(StoreActions.reviewedUser, {
        reportedUserId: report.reportedId,
        timeout: timeout,
        underage: underage
      });
    },
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.results
    ])
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  width:100px;
  height:100px;
  border-radius: 50px;
  object-fit: cover;
}

@media (max-width:767px) {
  .user-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>