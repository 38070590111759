<template>
    <modal
        :value="showing"
        title="Confirm"
        @hide="hideModal"
        @input="hideModal">
        <div>
            <p>{{ userMessage }}</p>
        </div>

        <template #footer>
            <div class="pull-left">
              <btn type="primary" @click="$emit('confirmed')">{{ yesTitle }}</btn>
            </div>
            <div class="pull-right">
              <btn @click="cancelClicked">{{ cancelTitle }}</btn>
            </div>
        </template>

    </modal>

</template>

<script type="text/javascript">

    export default {
        name: 'ConfirmationModal',
        props: {
            userMessage: {
                type:String,
                default:"Are you sure you want to peform this action?"
            },
            yesTitle: {
                type:String,
                default:"Yes"
            },
            cancelTitle: {
                type:String,
                default:"Cancel"
            },
            showing: {
                type: Boolean,
                required: true
            }
        },
        emits: ['confirmed', 'hidden', 'canceled'],
        methods: {
            hideModal() {
                this.$emit('hidden');
            },
            cancelClicked() {
                this.hideModal();
                this.$emit('canceled')
            }
        }
    }

</script>

<style scoped>
</style>
