<script >
export default {
  props: [
    "eventItem"
  ]
}
</script>

<template>
<div>
  <div v-if="eventItem.photo">
    <img
        :src="eventItem.photo.url"
        style="height: 150px;"
    />
  </div>
  <div v-if="eventItem.business.photo">
    <img
        :src="eventItem.business.photoUrl"
        style="height: 150px;"
    />
  </div>
</div>
</template>

<style scoped>

</style>