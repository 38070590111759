<script >
export default {
  props: [
    "me"
  ]
}
</script>

<template>
<div>
  <div v-if="me.photo">
    <img
        :src="me.photoUrl"
        style="height: 150px;"
    />
  </div>
</div>
</template>

<style scoped>

</style>