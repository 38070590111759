<template>
  <div class="bg-gray">
    <div class="container-fluid">
      <div class="user-card">
        <h2>Themes</h2>

        <table class="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Color</th>
              <th>User</th>
              <th>Featured</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          <tr
               v-for="(result, index) in results" :key="index"
          >
            <td>{{result.name}}</td>
            <td>
              <color-gradient :colors="colors(result)" />
            </td>
            <td><router-link v-if="result.user" :to="`/users/${result.user.id}`">
              @{{result.user.username}}
            </router-link></td>
            <td>{{result.featured ? 'Yes' : 'No'}}</td>
            <td>
              <button @click="removeFeatured(result.id)" v-if="result.featured" class="btn btn-warning">Remove Featured</button>
              <button @click="addFeatured(result.id)" v-else class="btn btn-default">Add Featured</button>
            </td>
          </tr>
          </tbody>
        </table>


      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'
import ColorGradient from "@/components/swiftspace/themeSearch/ColorGradient.vue";

export default {
  name: "ReportedUsers",
  components: {
    ColorGradient
  },
  data() {
    return {
      suspendConfirm: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.dispatch(StoreActions.search);
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    removeFeatured(id) {
      this.dispatch(StoreActions.setFeatured, {id: id, featured: false})
    },
    addFeatured(id) {
      this.dispatch(StoreActions.setFeatured, {id: id, featured: true})
    },
    colors(theme) {
      return theme.colors.map(it => {
        return it.color
      });
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
        state.results,
        state.page
    ])
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  width:100px;
  height:100px;
  border-radius: 50px;
  object-fit: cover;
}

@media (max-width:767px) {
  .user-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>