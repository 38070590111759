<template>
  <div class="bg-gray">
    <div class="container-fluid">
      <div class="user-card">
        <h2>Reported Events</h2>

        <div class="row"
            v-for="(result, index) in results" :key="index"
        >
          <div class="col-sm-12" v-if="result.event">
            <div v-if="result.event.photo">
              <img 
                :src="result.event.photo.url"
                style="height: 200px"
              />
            </div>
            <div v-else-if="result.event.business.photo">
              <img
                  :src="result.event.business.photoUrl"
                  style="height: 200px"
              />
            </div>
            <router-link v-if="result.event" :to="`/event/${result.eventId}`">{{result.event.title}}</router-link>

            <p><strong>{{result.reason}}</strong></p>

            <router-link v-if="result.user" :to="`/users/${result.user.id}`">Reported by: {{result.user.firstName}} {{result.user.lastName}} ({{result.user.username}})</router-link>
            <br><br>

            <button @click="review(result, true)" class="btn btn-danger">Remove Event</button>
            <br><br>
            <button @click="review(result, false)" class="btn btn-default">Mark Reviewed</button>
            <hr>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'

export default {
  name: "ReportedEvents",
  components: {
  },
  data() {
    return {
      suspendConfirm: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.dispatch(StoreActions.getReported);
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    review(report, remove) {
      this.dispatch(StoreActions.reviewedReported, {
        id: report.eventId,
        remove: remove
      });
    },
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.results
    ])
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  width:100px;
  height:100px;
  border-radius: 50px;
  object-fit: cover;
}

@media (max-width:767px) {
  .user-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>