<template>
  <div>
    <div style="margin-top: 10px">
      <div v-for="(option, index) in options" :key="option" class="option-item">
        <p class="option-text">
          {{option}}
        </p>
        <button @click="$emit('remove', index)" class="btn btn-danger"><i class="glyphicon glyphicon-remove" /></button>
      </div>
    </div>

    <div class="form-group" style="margin-top: 10px">
      <div class="input-group">
        <input class="form-control" v-model="nextOption" placeholder="..."/>
        <span class="input-group-btn">
            <button @click="addOption()" class="btn btn-primary">Add</button>
          </span>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      nextOption: ""
    }
  },
  props: {
    options: {
      default: []
    }
  },
  methods: {
    addOption() {
      this.$emit('addOption', this.nextOption)
      this.nextOption = ""
    }
  }
};
</script>

<style scoped>
.modal-body {
  padding: 15px;
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px;
  border-top: 1px solid #e9ecef;
}

.option-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.option-text {
  margin: 0;
  text-align: left;
  flex-grow: 1;
}

.option-item button {
  margin-left: 10px;
}
</style>
